import React  from 'react'; 
import { Link } from 'react-router-dom';
const Reflections = () => { 
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>  
                <li>Activity</li>
                 <li>Reflections</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Reflections</h1>
                    </div>
                </div>
                <div className="row">
                     <div className="col-12">
                     <img src="https://webapi.entab.info/api/image/HTSJ/public/Images/scoming-soon.jpg" className="img-fluid mx-auto d-block cm" alt="HTS"/>
                    </div>
                </div> 
            </div>
        </div>
     </>
  )
}

export default Reflections

