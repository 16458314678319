import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className="footer padded-sec">
        <div className='abs-list ftrShape1'> 
        </div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-xl-3 col-lg-6 col-sm-6 col-xs-12'>
               <Link to="/"><div className='ftr-logo'>
               
                <img src="https://webapi.entab.info/api/image/HTSJ/public/Images/logo.png" alt="Hill Top School" /> 
                <div>
                  <h4>Hill top School</h4>
                  <p>H.S. Road, Telco Colony, Jamshedpur - 831 004, Jharkhand</p>
                </div>
               
              </div>
              </Link>
            </div>
            
            <div className='col-xl-3 col-lg-6 col-sm-6 col-xs-12'>
              <div className='quick-link'>
                <h3>Quick Links</h3>
                <ul>
                  
                  <li><Link to="/About"><i class="bi bi-caret-right-fill"></i> ABOUT</Link></li>
                  {/* <li><Link to="/Admission"><i class="bi bi-caret-right-fill"></i> ADMISSION </Link></li> */}
                  <li><Link to="/TeacherBlog"><i class="bi bi-caret-right-fill"></i> TEACHERS’ BLOG</Link></li>
                  <li><Link to="/StudentBlog"><i class="bi bi-caret-right-fill"></i> STUDENTS’ BLOG</Link></li> 
                  <li><Link to="/ErpSoftware"><i class="bi bi-caret-right-fill"></i>  ERP SOFTWARE </Link></li>
                  <li><Link to="/Pphelp"><i class="bi bi-caret-right-fill"></i> PARENT PORTAL</Link></li> 
                  <li><Link to="/Tc"><i class="bi bi-caret-right-fill"></i>  TRANSFER CERTIFICATE </Link></li>
                  <li><Link to="#"><i class="bi bi-caret-right-fill"></i> ALUMNI REGISTRATION</Link></li> 
                   <li><Link to="https://hilltop.symphonytek.com/fee/login.php" target="_blank"><i class="bi bi-caret-right-fill"></i> Fee payment</Link></li>
                   <li><Link to="https://entab.online/HTSJSR" target="_blank"><i class="bi bi-caret-right-fill"></i> ERP Login</Link></li>
                  <li><Link to="#"><i class="bi bi-caret-right-fill"></i> CAREER</Link></li>
                  <li><Link to="/ContactUs"><i class="bi bi-caret-right-fill"></i> CONTACT US</Link></li>
                </ul>
              </div>
            </div>
           
            <div className='col-xl-3 col-lg-6 col-sm-6 col-xs-12 address'>
              <h3>Address</h3>
              <p><i class="bi bi-house-door-fill"></i> <span><Link to="https://maps.app.goo.gl/Uhg9bm8D8VPMzVmCA" target="_blank">H.S. Road, Telco Colony,<br /> Jamshedpur - 831 004, Jharkhand</Link></span></p>
              <p><i class="bi bi-telephone-fill"></i> <span><a href="tel: 0657 - 2286677">0657 - 2286677</a><br /><a href="tel: 0657 - 2286677">0657 - 2284061</a></span></p>
              <p><i class="bi bi-envelope-fill"></i> <span> <a href="mailto: hilltopschooljsr@gmail.com">hilltopschooljsr@gmail.com</a><br /> <a href="mailto: contact@hilltopschooljamshedpur.org">contact@hilltopschooljamshedpur.org</a></span></p>
            </div>
            <div className='col-xl-3 col-lg-6 col-sm-6 col-xs-12'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1093.6756218129372!2d86.24756575444263!3d22.77756404941729!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa3ae45e5108c79f6!2sHill%20Top%20School%2C%20Jamshedpur!5e0!3m2!1sen!2sin!4v1582277385762!5m2!1sen!2sin" width="100%" height="200px" frameborder="0" allowfullscreen=""></iframe>
              </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <ul className='socialMedia'>
                <li><Link to="https://www.facebook.com/htsjsr" target="_blank"><img src="https://webapi.entab.info/api/image/HTSJ/public/Images/facebook.png" alt="Hill Top School" /></Link></li>
                <li><Link to="https://www.youtube.com/@HillTopSchoolJamshedpur" target="_blank"><img src="https://webapi.entab.info/api/image/HTSJ/public/Images/youtube.png" alt="Hill Top School" /></Link></li>
                <li><Link to="https://www.instagram.com/hts.jsr/?igshid=YmM0MjE2YWMzOA%3D%3D" target="_blank"><img src="https://webapi.entab.info/api/image/HTSJ/public/Images/insta.png" alt="Hill Top School" /></Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='copyright'>
      ©️ 2024 hill top school | Designed & developed by Entab Infotech: CampusCare®️ 
      </div>
    </>
  );
}

export default Footer;
