import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getPrincipalMessage } from "../Service/Api";

const Testimonials = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const principalData = await getPrincipalMessage();
        setData(principalData);
      } catch (error) {
        console.log("Data Error", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  const emptyData = [
    {
      category: "Principal",
      title: "Principal Message",
      name: "Mrs. Uma Tiwary",
      designation: (
        <>
          principal
          <br />
          hill top school
        </>
      ),
      message: (
        <>
          I am delighted to extend a warm welcome to each and every one of you
          to Hill Top School. As we embark on a new academic year, we are filled
          with excitement and anticipation for the wonderful learning journey
          ahead. At Hill Top School, we believe in fostering an environment that
          nurtures academic excellence, character development, and a sense of
          community.
          <br />
          As we embark on a new academic year, we are filled with excitement and
          anticipation for the wonderful learning journey ahead. At Hill Top
          School, we believe in fostering an environment that nurtures academic
          excellence, character development, and a sense of community. Our
          dedicated team of educators is committed to providing the highest
          quality of education and equipping our students with the knowledge,
          skills, and values necessary to thrive in an ever-changing world.
        </>
      ),

      image:
        "https://webapi.entab.info/api/image/HTSJ/public/Images/principal.jpg",
      link: "/",
    },
  ];
  return (
    <>
      {data && data.length > 0
        ? data.map((item, index) => (
            <div className="message-container" key={index}>
              <div className="messagebox">
                <div className="messagedesc">
                  <div className="maintitle">
                    <h3>{item.category}'s Message</h3>
                  </div>
                  <div className="msginfo">
                    <div dangerouslySetInnerHTML={{ __html: item.message }} />
                  </div>
                </div>
                <div className="messageboximg">
                  <p>
                    {item.name} <span> {item.category}</span>{" "}
                  </p>
                </div>
              </div>

              <div className="messageimg">
                <div className="testimonialimg">
                  <img
                    src={`https://webapi.entab.info/api/image/${item.attachments}`}
                    alt="HTS"
                    className="img-fluid"
                  />
                </div>
                <div className="mainbtn button">
                  <Link to="/PrincipalMessage">READ MORE</Link>
                </div>
              </div>
            </div>
          ))
        : emptyData.map((data) => (
            <div className="message-container">
              <div className="messagebox">
                <div className="messagedesc">
                  <div className="maintitle">
                    <h3>{data.category}'s Message</h3>
                  </div>
                  <p>{data.message} </p>
                </div>
                <div className="messageboximg">
                  <p>
                    {data.name} <span> {data.category}</span>{" "}
                  </p>
                </div>
              </div>

              <div className="messageimg">
                <div className="testimonialimg">
                  <img src={data.image} alt="HTS" className="img-fluid" />
                </div>
                <div className="mainbtn button">
                  <Link to="/PrincipalMessage">READ MORE</Link>
                </div>
              </div>
            </div>
          ))}
    </>
  );
};

export default Testimonials;
