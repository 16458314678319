import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { form1 } from "../Service/Api";
import { getHeader } from "../config";
const Careers = () => { 
       const [successMessage, setSuccessMessage] = useState("");
    const [formData, setFormData] = useState({
        name: "", 
        phone: "",
        subject: "",
        profession: "",
        experience: "",
        attachments: null,  
        
    });
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        if (e.target.type === "file") {
            setFormData({
                ...formData,
                attachments: e.target.files[0], // Save the file object
            });
            setErrors({ ...errors, attachments: "" }); // Clear any previous error for file
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
            setErrors({ ...errors, [e.target.name]: "" }); // Clear the error for the field being changed
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required.";
        // if (!formData.subject) newErrors.subject = "Teaching Subject is required.";
        if (!formData.phone) newErrors.phone = "Contact Number is required.";
        if (!formData.profession) newErrors.profession = "Post Applied  is required.";
        if (!formData.experience) newErrors.experience = "Total Exp is required.";
        if (!formData.attachments) newErrors.attachments = "Please upload CV (  PDF).";
        
        if (formData.attachments) {
            const validTypes = ['application/pdf'];
            if (!validTypes.includes(formData.attachments.type)) {
                newErrors.attachments = "Invalid file type. Only PDF are allowed.";
            }
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const type = 'XYZ'; 
        console.log('Submitting form with type:', type); // Debugging line

        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }
        try {
            const { header } = getHeader(); // Make sure this function returns the correct headers
            const response = await form1(formDataToSend, header, type); 
            console.log('Form submitted successfully', response);
            setSuccessMessage("Your Form Submitted Successfully.");
            setFormData({
                name: "", 
                phone: "",
                subject: "",
                profession: "",
                experience: "",
                attachments: null,
                
            });
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>  
                 <li>Careers</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Careers</h1>
                    </div>
                </div>
                <div className="row">
                          <div className="col-lg-12">
                            <form className='careerform-feilds' onSubmit={handleSubmit}>
                            <div>
                                <label>Full Name</label>
                                <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange}/>
                                {errors.name && <div className="error">{errors.name}</div>}
                            </div> 
                            <div>
                                <label>Contact  Number</label>
                                <input type="tel" pattern="\d{10}"  inputMode="numeric"  name="phone" className="form-control" maxlength={10} value={formData.phone} onChange={handleChange}/>
                                {errors.phone && <div className="error">{errors.phone}</div>}
                            </div>   
                            <div>
                            <label>Post Applied For</label>
                            <input type="text"  name="profession" className="form-control" value={formData.profession} onChange={handleChange} />
                            {errors.profession && <div className="error">{errors.profession}</div>}
                        </div>
                            <div>
                                <label>Teaching Subject (Optional)</label>
                                <input type="text" name="subject" className="form-control" value={formData.subject} onChange={handleChange}/>
                                {/* {errors.subject && <div className="error">{errors.subject}</div>} */}
                            </div>
                        <div>
                            <label>Total Exp.</label>
                            <input type="text"  name="experience"className="form-control"  value={formData.experience} onChange={handleChange} />
                            {errors.experience && <div className="error">{errors.experience}</div>}
                        </div> 
                        <div>
                        <label>Upload CV  (Only in Pdf Format):</label>
                        <input className="form-control"
                            type="file"
                            name="attachments"
                            accept=".pdf"
                            onChange={handleChange}
                        />
                        {errors.attachments && <div className="error">{errors.attachments}</div>}
                    </div>
                        
                    <div className="submit">
                    <input type="submit" value="Submit" /> 
                </div>
                <div className="row"> 
                    <div className="col-lg-12 col-md-12">
                    {successMessage && <div className='primary-alert'>{successMessage}</div>} 
                    </div>
                </div>
                        </form>
                             </div> 
                        </div>
                    </div>
                </div> 
         
     </>
  )
}

export default Careers

