import React, { useEffect, useState } from "react";
import { getEvents } from "../Service/Api";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";
const HomeEvent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventData = await getEvents();
        setData(eventData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const emptyArray = [
    {
      title: "Event Title",
      description: "Stay Tunned For More Updates",
      date: "DD.MM",
      time: "00:00",
      venue: "School Campus",
    },
    {
      title: "Event Title",
      description: "Stay Tunned For More Updates",
      date: "DD.MM",
      time: "00:00",
      venue: "School Campus",
    },
    {
      title: "Event Title",
      description: "Stay Tunned For More Updates",
      date: "DD.MM",
      time: "00:00",
      venue: "School Campus",
    },
    {
      title: "Event Title",
      description: "Stay Tunned For More Updates",
      date: "DD.MM",
      time: "00:00",
      venue: "School Campus",
    },
  ];
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="eventbg">
        <Slider {...settings}>
          {data && data.length > 0
            ? data.map((item, index) => {
                const date = new Date(item.date);
                const mon = monthNames[date.getMonth()];
                const eventdate = date.getDate();
                console.log(mon);

                return (
                  <div className="item" key={index}>
                    <div className="homeeventbox">
                      <div className="homeeventboximg">
                        <p>
                          <span>
                            <img
                              src="https://webapi.entab.info/api/image/HTSJ/public/Images/location.gif"
                              className="img-fluid"
                              alt="Hill Top School"
                            />{" "}
                            {item.venue} / {item.time}{" "}
                          </span>
                        </p>
                        {item.images.length > 0 ? (
                          <LazyLoad
                            offset={10}
                            placeholder={
                              <img
                                src="https://webapi.entab.info/api/image/HTSJ/public/Images/events.jpg"
                                alt="Loading..."
                                className="img-fluid"
                              />
                            }>
                            <img
                              src={`https://webapi.entab.info/api/image/${item.images}`}
                              className="img-fluid"
                              alt="HTSJ"
                            />
                          </LazyLoad>
                        ) : (
                          <img
                            src="https://webapi.entab.info/api/image/HTSJ/public/Images/events.jpg"
                            className="img-fluid"
                            alt="HTS"
                          />
                        )}
                      </div>
                      <div className="homeeventboxdesc">
                        <div className="homeeventboxtitle">
                          <h3>{item.title}</h3>
                          <p>{item.description}</p>
                          <div className="homeeventboxbottom">
                            <span>
                              {eventdate}, {mon}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : emptyArray.map((item, index) => (
                <div className="item" key={index}>
                  <div className="homeeventbox">
                    <div className="homeeventboximg">
                      <p>
                        <span>
                          <img
                            src="https://webapi.entab.info/api/image/HTSJ/public/Images/location.gif"
                            className="img-fluid"
                            alt="Hill Top School"
                          />{" "}
                          {item.venue} / {item.time}{" "}
                        </span>
                      </p>
                      <img
                        src="https://webapi.entab.info/api/image/HTSJ/public/Images/events.jpg"
                        className="img-fluid"
                        alt="Hill Top School"
                      />
                    </div>
                    <div className="homeeventboxdesc">
                      <div className="homeeventboxtitle">
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                        <div className="homeeventboxbottom">
                          <span>{item.date} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </Slider>
        <button className="button">
          <Link to="/Events">Read More</Link>
        </button>
      </div>
    </>
  );
};

export default HomeEvent;
