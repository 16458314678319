import React from 'react'
import { Link } from 'react-router-dom'
const Campuscare = () => {
  return (
     <>
      <div className="campuslogo">
         <Link to="https://entab.online/HTSJSR" target="_blank"><img src="https://webapi.entab.info/api/image/HTSJ/public/Images/Campulogo.png" className="img-fluid float-end campus" alt="HTS"/></Link>
      </div>
      </>
        
  )
}

export default Campuscare
