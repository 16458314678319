import React from 'react'
import Popup from '../Component/Popup' 
import HomeSlider from '../Component/HomeSlider'
import TopperXII from '../Component/TopperXII'
import TopperX from '../Component/TopperX' 
import Events from '../Component/Events'
import News from '../Component/News'
import Calender from '../Component/Calender'
import Campuscare from '../Component/Campuscare'
import OnlineRegistration from '../Component/OnlineRegistration'
// import Facilities from'../Component/Facilities'
import Gallery from '../Component/Gallery'
import Message from '../Component/Message'  
import { Link } from 'react-router-dom'
import ClassTabs from '../Component/ClassTabs'
import FacebookPagePlugin from '../Component/Facebook'
import Instagram from'../Component/Instagram' 
import YouTubeWidget from '../Component/YouTubewidget'
const Home = () => {
  return (
    <>
        <Campuscare />  
      <Popup /> 
      <OnlineRegistration />
      <HomeSlider />
      <div className="aboutmidsec padded-sec">
        <div className='button'>
          <Link to="/" target="_blank">
            <p>Admission</p>
          </Link>
        </div>
        <div className='button'>
          <Link to="https://hilltop.symphonytek.com/fee/login.php" target='_blank'>
            <p>Fee payment</p>
          </Link>
        </div>
        <div className='button'>
          <Link to="https://forms.gle/szVqfZ8adA84eu5LA" target="_blank">
            <p>alumnae registration</p></Link>
        </div>
        <div className='phone'>
          <a href="tel:0657-2284061">
            <img src="https://webapi.entab.info/api/image/HTSJ/public/Images/phone.gif" alt="Hill Top school" />
          </a>
        </div>
        <div className='button'>
          <Link to="/Toppers">
            <p>Results</p>
          </Link>
        </div>
        <div className='button'>
          <Link to="/Gallery">
            <p> Gallery</p>
          </Link>
        </div>
        <div className='button'>
          <Link to="https://entab.online/HTSJSR" target="_blank">
            <p>ERP Login</p>
          </Link>
        </div>
      </div>
      <div className="aboutsec">
        <div className="container-fluid fulwidth">
          <div className="row">
            <div className="col-lg-6 about-bg">
              <div class="about-news">
                <div className='title'>
                  <p>about us</p>
                  <h2>Hill Top School, Jamshedpur</h2>
                </div>
                <p>Hill Top School was started on the 2nd of August 1976 with 80 students, 4 teachers and 2 classrooms. The school received temporary affiliation to the Indian School Certificate Examinations, New Delhi in 1987, while the Senior Secondary segment was launched in 1993. Later in the year 2000, the school was awarded permanent affiliation in recognition of the high standards of excellence it strives to maintain. Today, more than three decades since its inception, it is one of the premier English medium co-educational institutions in the city.</p>
                <p>Building on a rigorous academic framework designed by the International Baccalaureate Organization, our faculty pulls from the latest pedagogy to develop a fully aligned curriculum that is assessed externally by the IB and the Council for International Schools. The school reviews its curriculum annually using data from both external and school-based assessments, incorporating changes as suggested by the latest research, the International Baccalaureate and the Council of International Schools.</p>
                <Link to="/About"><button className='button'>Read More</button></Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-img">
                <div className='abt_abs abs-img1'><img src='https://webapi.entab.info/api/image/HTSJ/public/Images/about1.jpg' alt="Hill Top School" /></div>
                <div className='abt_abs abs-img2'><img src='https://webapi.entab.info/api/image/HTSJ/public/Images/about2.jpg' alt="Hill Top School" /></div>
                <div className='center-img'><img src='https://webapi.entab.info/api/image/HTSJ/public/Images/aboutimg.png' className='abs-img3' alt="Hill Top School" /></div>
                <div className='abt_abs abs-img4'><img src='https://webapi.entab.info/api/image/HTSJ/public/Images/about3.jpg' alt="Hill Top School" /></div>
                <div className='abt_abs abs-img5'><img src='https://webapi.entab.info/api/image/HTSJ/public/Images/about4.jpg' alt="Hill Top School" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='classSection'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <ClassTabs />

            </div>

          </div>
        </div>
      </div>
      <div className='calender'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='title'>
                <h2>hill top school, Jamshedpur </h2>
              </div>
              <Calender />

            </div>

          </div>
        </div>
      </div>
      <div className="event">
        <div className="container fulwidth">
          <div className="row">
            <div className="col-lg-12 section-title">
              <div className='title'> 
                <h2>FROM THE DESK OF</h2>
              </div>
            </div>
            <div className="col-lg-12">
              <Message />
            </div>
          </div>
        </div>
      </div>
      <div className='events padded-sec'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-xl-6 col-md-12'>
            <div className='title'> 
                <h2>Events section</h2>
              </div>
              <Events />
            </div>
            <div className='col-xl-6 col-md-12'>
            <div className='title'> 
                <h2>Latest News/Announcement</h2>
              </div>
              <News />
            </div>
          </div>
        </div>
      </div>
      <div className="topper_bg padded-sec">
        <div className="container-fluid section-title">
          <div className="row">
            <div className='col-md-12'>
              <img src="https://webapi.entab.info/api/image/HTSJ/public/Images/topper.png" className='img-fluid m-auto d-block' />
            </div>
            <div className="col-lg-6">
              <div className='topper-block'>
              <div className='title'> 
                <h2>ICSE 2024 Toppers</h2>

                <div className='arrrow-btn'>
                  {/* <Link to="/">
                    <i class="bi bi-chevron-right"></i></Link>*/}
                </div> 
              </div>
              <TopperX />
              </div>
              <button className='button'><Link to="/Toppers">Read More</Link></button>
            </div>    
                
            <div className="col-lg-6">
              <div className='topper-block'>
              <div className='title'> 
                <h2>ISC 2024 Toppers</h2>
                <div className='arrrow-btn'>
                  {/* <Link to="/">
                    <i class="bi bi-chevron-right"></i>
                  </Link> */}
                </div>
              </div>
              <TopperXII />
              </div>
              <button className='button'><Link to="/ToppersXII">Read More</Link></button>
            </div>
          </div>
        </div>
      </div>
<img src="https://webapi.entab.info/api/image/HTSJ/public/Images/shape.png" className='wid-100'/>
    
      <div className="homegallery padded-sec">
        <div className="container-fluid fulwidth">
          <div className="row">
            <div className='col-md-12 section-title'>
              <div className='title'> 
                <h2>Activities in Images</h2>
              </div>
            </div>
            <div className="col-lg-12">

              <Gallery />
              <button className='button'><Link to="/Gallery">Read More</Link></button>
            </div>
          </div>
        </div>
      </div>
      <div className='facebook'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <FacebookPagePlugin />
            </div>
            <div className='col-md-4'>
              <Instagram/>
            </div>
            <div className='col-md-4'>
              <YouTubeWidget/>
                 {/* <div className='facebook_title'>
                <h3>facebook</h3>
                <p>Facebook helps you connect and share with the people in your life  </p>
                </div> */}
              </div>
          </div>
        </div>
      </div> 
    </>
  )
}

export default Home