import React  from 'react'; 
import { Link } from 'react-router-dom';
const About = () => { 
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                 <li><Link to="/"> Home</Link> </li>  
                 <li>About</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>About</h1>
                    </div>
                </div>
                <div className="row">
                     <div className="col-12">
                        <p> Hill Top School was started on the 2nd of August 1976 with 80 students, 4 teachers and 2 classrooms. The school received temporary affiliation to the Indian School Certificate Examinations, New Delhi in 1987, while the Senior Secondary segment was launched in 1993. Later in the year 2000, the school was awarded permanent affiliation in recognition of the high standards of excellence it strives to maintain. Today, more than three decades since its inception, it is one of the premier English medium co-educational institutions in the city.</p>
                        <h3>AT A GLANCE</h3>
                         <div className="glancebox">
                             <div className="glancediv">
                                 <div className="glanceno">
                                    <p>14:1</p>
                                 </div>
                                 <div className="glancedesc">
                                       <p>Average Class Size For Grades SK-12</p>
                                 </div>
                             </div>
                             <div className="glancediv">
                                 <div className="glanceno">
                                    <p>3000+</p>
                                 </div>
                                 <div className="glancedesc">
                                       <p>Total Student Body</p>
                                 </div>
                             </div>
                             {/*<div className="glancediv">
                                 <div className="glanceno">
                                    <p>69</p>
                                 </div>
                                 <div className="glancedesc">
                                       <p>Different Countries The Student Body Represents</p>
                                 </div>
                             </div>*/}
                             <div className="glancediv">
                                 <div className="glanceno">
                                    <p>1ST</p>
                                 </div>
                                 <div className="glancedesc">
                                       <p>1:1 Program In The Mid-South</p>
                                 </div>
                             </div>
                         </div> 

                         {/*<p>. Building on a rigorous academic framework designed by the International Baccalaureate Organization, our faculty pulls from the latest pedagogy to develop a fully aligned curriculum that is assessed externally by the IB and the Council for International Schools. The school reviews its curriculum annually using data from both external and school-based assessments, incorporating changes as suggested by the latest research, the International Baccalaureate and the Council of International Schools.</p>
                   
                            <br/>
                         <div className="clearfix"> 
                         <img src="https://webapi.entab.info/api/image/HTSJ/public/Images/Rajesh.jpg" className="col-md-3 float-md-end  left-img img-fluid"/>
                        
                            <p> "My years at Lausanne played a fundamental role in my academic success at the Naval Academy. I was a member of the full diploma IB program at Lausanne, which challenged my time management skills early. I learned how to balance taking these rigorous courses, while also participating in multiple sports on campus. The teachers at Lausanne always challenged me to take myself to the next level and that served me well throughout my college years."
                            </p>
                            <div className="mesdeskimg"> <h3> Rajesh Kumar  </h3> <h5>  Student  </h5></div>
                            </div>   */}
                     </div> 
                </div>
                
            </div>
        </div>
     </>
  )
}

export default About

