import React, { useEffect, useState } from "react";
import { getEventsinner } from "../Service/Api";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

const Events = () => {
  useEffect(() => {
    document.title = "Events - Hill Top School, Jamshedpur";
  }, []);
  const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState("All");
  const [monthOptions, setMonthOptions] = useState(["All"]);
  const [yearRanges, setYearRanges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const Year = `${currentYear}`;
    setSelectedYear(Year);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const galleryData = await getEventsinner();
      setData(galleryData);

      const uniqueYears = Array.from(
        new Set(
          galleryData.flatMap((item) => {
            if (item.date) {
              const year = new Date(item.date).getFullYear();
              return [year];
            } else {
              return [];
            }
          })
        )
      );
      const uniqueYearRanges = uniqueYears.map((year) => `${year}`);
      uniqueYearRanges.sort((a, b) => b - a);
      setYearRanges(uniqueYearRanges);

      const uniqueMonths = Array.from(
        new Set(
          galleryData.flatMap((item) => {
            if (item.date) {
              const month = new Date(item.date).getMonth();
              return [month];
            } else {
              return [];
            }
          })
        )
      );
      const monthOptions = uniqueMonths
        .sort((a, b) => a - b)
        .map((month) => monthNames[month]);
      setMonthOptions(["All", ...monthOptions]);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let filteredData = data;
    if (selectedYear !== "All") {
      filteredData = filteredData.filter((item) => {
        if (item.date && item.date.trim() !== "") {
          const year = new Date(item.date).getFullYear();
          return parseInt(selectedYear) === year;
        } else {
          return false;
        }
      });
    }
    if (selectedMonth !== "All") {
      filteredData = filteredData.filter((item) => {
        if (item.date && item.date.trim() !== "") {
          const month = new Date(item.date).getMonth();
          return monthNames[month] === selectedMonth;
        } else {
          return false;
        }
      });
    }
    if (searchQuery) {
      filteredData = filteredData.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Sort the filtered data by month in descending order
    filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));
    setFilteredData(filteredData);
  }, [selectedYear, selectedMonth, searchQuery, data]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  return (
    <>
      <div className="innerslide">
        <ul className="breadcrumb">
          <li>
            <Link to="/"> Home</Link>
          </li>
          <li>Events</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Events</h1>
            </div>
          </div>
          <div className="row tabs-dynamic">
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="count-val">
                <p>Total Count: {filterData.length}</p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="month-selection">
                <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="yearSelection">
                  <option value="All">All</option>
                  {yearRanges.map((yr) => (
                    <option key={yr} value={yr}>
                      {yr}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="month-selection">
                <select
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  className="monthSelect">
                  {monthOptions.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
              <div className="searchBox">
                <input
                  type="text"
                  id="myInput"
                  name="name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search Here.."
                />
              </div>
            </div>
          </div>
          <div className="row years year2024 showYear">
            {filterData.length > 0 ? (
              filterData.map((item, index) => (
                <div
                  className="col-xl-6 col-lg-6 col-md-12 col-sm-12 eventCount"
                  key={index}>
                  <div className="eventsnewsbox">
                    <div className="newsboximg">
                      <LazyLoad
                        offset={10}
                        placeholder={
                          <img
                            src="https://webapi.entab.info/api/image/HTSJ/public/Images/sld0.jpg"
                            alt="Loading..."
                            className="img-fluid"
                          />
                        }>
                        <img
                          src={`https://webapi.entab.info/api/image/${item?.images[0]}`}
                          className="img-fluid"
                          alt=""
                        />
                      </LazyLoad>
                    </div>
                    <div className="newsboxdesc">
                      <div className="newspgh">
                        <h5>{item.title}</h5>
                        <p>{item.description}</p>
                        <span>
                          <b>Location: </b> {item.venue}
                        </span>
                      </div>
                      <div className="newsdate">
                        <span>
                          <i className="bi bi-calendar"></i>{" "}
                          {formatDate(item.date)}
                        </span>
                        <span>
                          <i className="bi bi-alarm"></i> {item.time}
                        </span>
                        <Link
                          to={`https://webapi.entab.info/api/image/${item?.attachments[0]}`}
                          target="_blank">
                          <i className="bi bi-paperclip"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 eventCount">
                <div className="eventsnewsbox">
                  <div className="newsboximg">
                    <img
                      src="https://webapi.entab.info/api/image/HTSJ/public/Images/events.jpg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="newsboxdesc">
                    <div className="newspgh">
                      <h5>Stay tuned for latest updates</h5>
                      <p>Stay tuned for latest updates</p>
                      <span>
                        <b>Location: </b> School
                      </span>
                    </div>
                    <div className="newsdate">
                      <span>
                        <i className="bi bi-calendar"></i> DD/MM/YYYY
                      </span>
                      <span>
                        <i className="bi bi-alarm"></i> 10
                      </span>
                      <Link to="#" target="_blank">
                        <i className="bi bi-paperclip"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
