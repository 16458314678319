import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getTopper } from "../Service/Api"; // Ensure getTopper is correctly imported
import LazyLoad from "react-lazyload";

const ToppersXII = () => {
  useEffect(() => {
    document.title = "Toppers XII - Hill Top School, Jamshedpur";
  }, []);
  const [data, setData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(); // Initialize to undefined
  const [yearRanges, setYearRanges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    const yearRange = `${lastYear}-${currentYear}`;
    setSelectedYear(yearRange);
    fetchData(yearRange); // Fetch data for the current academic year initially
  }, []);

  const fetchData = async (year) => {
    try {
      const toppersData = await getTopper();
      const filteredData = toppersData.filter(
        (item) => item.sessionYear === year && item.class === "XII"
      );
      setData(filteredData);

      const years = new Set(toppersData.map((item) => item.sessionYear));
      setYearRanges([...years]); // Populate yearRanges directly from data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!selectedYear) return; // Don't filter if selectedYear isn't set yet
    const filteredData = data.filter(
      (item) =>
        item.sessionYear === selectedYear &&
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setData(filteredData);
  }, [selectedYear, searchQuery]);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    fetchData(e.target.value); // Refetch or filter the data when year changes
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <div className="innerslide">
        <ul className="breadcrumb">
          <li>
            <Link to="/"> Home</Link>{" "}
          </li>
          <li>Toppers ISC </li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Toppers ISC </h1>
            </div>
          </div>
          <div className="row tabs-dynamic">
            <div className="col-md-3">
              <div className="month-selection">
                <select
                  id="yearSelection"
                  onChange={handleYearChange}
                  value={selectedYear}>
                  <option value="All">Select Years</option>
                  {yearRanges.map((yr, index) => (
                    <option key={index} value={yr}>
                      {yr}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="searchBox">
                <input
                  type="text"
                  id="myInput"
                  name="name"
                  autoComplete="off"
                  placeholder="Search Here.."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {data?.length > 0 ? (
              data
                ?.filter((item) => item.class === "XII")
                .map((item, index) => (
                  <div className="col-xl-2 col-lg-4 col-md-4 col-6" key={index}>
                    <div className="toppers-inner">
                      <LazyLoad
                        offset={10}
                        placeholder={
                          <img
                            src="https://webapi.entab.info/api/image/HTSJ/public/Images/trophy.png"
                            alt="Loading..."
                            className="img-fluid"
                          />
                        }>
                        <img
                          src={
                            item.attachments && item.attachments[0]
                              ? `https://webapi.entab.info/api/image/${item.attachments[0]}`
                              : "/images/trophy.png"
                          }
                          className="img-fluid"
                          alt="Hill Top School, Jamshedpur"
                        />
                      </LazyLoad>
                      <h3>{item.name}</h3>
                      <p>{item.stream}</p>
                      <h5>{item.division}</h5>
                    </div>
                  </div>
                ))
            ) : (
              <div className="col-xl-2 col-lg-4 col-md-4 col-6">
                <div className="toppers-inner">
                  <img
                    src="https://webapi.entab.info/api/image/HTSJ/public/Images/strophy.png"
                    className="img-fluid"
                    alt="Hill Top School, Jamshedpur"
                  />
                  <h3> Student Name</h3>
                  <p> Subject</p>
                  <h5> % </h5>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ToppersXII;
