import React, { useEffect, useState } from "react";
import { getGallery } from "../Service/Api";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";
const Gallery = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const galleryData = await getGallery();
        setData(galleryData);
      } catch (error) {
        console.log("Error fetching gallery data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const empty = [
    { title: "Hill Top School, Jamshedpur " },
    { title: "Hill Top School, Jamshedpur " },
    { title: "Hill Top School, Jamshedpur " },
    { title: "Hill Top School, Jamshedpur " },
    { title: "Hill Top School, Jamshedpur " },
    { title: "Hill Top School, Jamshedpur " },
  ];
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {data.length > 0
          ? data.map((item, index) => (
              <div className="item" key={index}>
                <div className="galleryimg">
                  <div className="galleryimgbox">
                    <LazyLoad
                      offset={10}
                      placeholder={
                        <img
                          src="https://webapi.entab.info/api/image/HTSJ/public/Images/gal-1.JPG"
                          alt="Loading..."
                          className="img-fluid"
                        />
                      }>
                      <img
                        src={`https://webapi.entab.info/api/image/${item.attachments[0]}`}
                        className="img-fluid"
                        alt="Hill Top School"
                      />
                    </LazyLoad>
                    <div className="gallerydesc">
                      <Link to="/Gallery" className="gallery_button">
                        View Gallery
                      </Link>
                      <h6>{item.title}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : empty.map((item) => (
              <div className="item" key={item.id}>
                <div className="galleryimg">
                  <div className="galleryimgbox">
                    <img
                      src="https://webapi.entab.info/api/image/HTSJ/public/Images/gal-1.JPG"
                      className="img-fluid"
                      alt="Hill Top School"
                    />
                    <div className="gallerydesc">
                      <Link to="/Gallery" className="gallery_button">
                        View Gallery
                      </Link>
                      <h6>{item.title}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
      </Slider>
    </>
  );
};

export default Gallery;
