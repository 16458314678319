import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getTopper } from "../Service/Api"; // Ensure getTopper is correctly imported
import LazyLoad from "react-lazyload";

const Toppers = () => {
  useEffect(() => {
    document.title = "Toppers X - Hill Top School, Jamshedpur";
  }, []);

  const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [yearRanges, setYearRanges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const Year = `${currentYear - 1}-${currentYear}`;
    setSelectedYear(Year);
    console.log("get current Year", Year);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const galleryData1 = await getTopper();
      const galleryData = galleryData1.filter((item) => item.class === "X");
      setData(galleryData);

      // Extract unique years
      const uniqueYears = Array.from(
        new Set(galleryData.map((item) => item.sessionYear))
      );

      console.log("Unique Years:", uniqueYears);
      setYearRanges(uniqueYears);
      setFilteredData(galleryData); // Initialize filteredData with all data
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Filter data based on selected year and search query
    let filteredData = data;
    if (selectedYear !== "All") {
      filteredData = filteredData.filter(
        (item) => item.sessionYear === selectedYear
      );
    }
    if (searchQuery) {
      filteredData = filteredData.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Sort data by division in descending order
    filteredData.sort(
      (a, b) => parseFloat(b.division) - parseFloat(a.division)
    );

    setFilteredData(filteredData);
  }, [selectedYear, searchQuery, data]);
  return (
    <>
      <div className="innerslide">
        <ul className="breadcrumb">
          <li>
            <Link to="/"> Home</Link>{" "}
          </li>
          <li>Toppers ICSE </li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Toppers ICSE </h1>
            </div>
          </div>
          <div className="row tabs-dynamic">
            <div className="col-md-6">
              <div className="month-selection">
                <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="yearSelection">
                  <option value={"All"}>All</option>
                  {yearRanges.map((yr) => (
                    <option key={yr} value={yr}>
                      {yr}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="searchBox">
                <input
                  type="text"
                  id="myInput"
                  name="name"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search Here.."
                />
              </div>
            </div>
          </div>
          <div className="row">
            {filterData.length > 0 &&
              filterData.map((item, index) => (
                <div className="col-xl-2 col-lg-4 col-md-4 col-6" key={index}>
                  <div className="toppers-inner">
                    <LazyLoad
                      offset={10}
                      placeholder={
                        <img
                          src="https://webapi.entab.info/api/image/HTSJ/public/Images/trophy.png"
                          alt="Loading..."
                          className="img-fluid"
                        />
                      }>
                      <img
                        src={
                          item.attachments && item.attachments[0]
                            ? `https://webapi.entab.info/api/image/${item.attachments[0]}`
                            : "/images/trophy.png"
                        }
                        className="img-fluid"
                        alt="Hill Top School, Jamshedpur"
                      />
                    </LazyLoad>
                    <h3>{item.name}</h3>
                    <p>{item.stream}</p>
                    <h5>{item.division}</h5>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Toppers;
