import React, { useEffect, useState } from 'react'
import { getHighlight } from '../Service/Api';
import { Link } from 'react-router-dom'
const Highlights = () => {
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(true);
  const [error,setError] = useState(null);

    useEffect(() => {
          const fetchData = async  () => {
               try{
                  const response = await getHighlight();
                  setData(response);
               } catch(error) {
                 setError(error)
               } finally {
                 setLoading(false)
               }
          }
          fetchData();
    },[]);
   
    const emptyData = [
            {id:1, title:"Stay Tunned For More Updates"},
            {id:2, title:"Stay Tunned For More Updates"},
            {id:3, title:"Stay Tunned For More Updates"}

    ]
  return (
    <>
      <div className="highlights">
      <marquee onMouseOver={(event) => event.currentTarget.stop()}   onMouseOut={(event) => event.currentTarget.start()}>
      {data && data.length > 0 ? (data.map((item,index) =>(
        <p key={index}><Link to={`https://webapi.entab.info/api/image/${item.attachments[0]}`} target="_blank"> {item.title}  <i className="bi bi-paperclip"></i></Link>  | </p>
           ))):(emptyData.map((item) =>(
               <p key={item.id}><Link to="#" target="_blank"> {item.title}  <i className="bi bi-paperclip"></i></Link>  | </p>
           )))}
                   
             </marquee>
      </div>
    </>
  )
}

export default Highlights
